<template>
  <div id="myProject" class="myProject">
    <v-alert dense class="alert" :value="tipsInfo" :type="tipsInfoType">{{ tipsInfoContent }}</v-alert>
    <div class="top_content">
      <div class="left">
        <v-text-field
          v-model="search"
          dense
          @keyup.enter.native="PostSearch()"
          height="32"
          prepend-icon="mdi-magnify"
          background-color="#E3ECF7"
          rounded
          clearable
          solo
          filled
          label="请输入项目名称"
        ></v-text-field>
      </div>
    </div>
    <!-- 列表 -->
    <div class="tab_content">
      <v-tabs-items class="my-4 tabs_items">
        <v-row>
          <v-col v-for="(item, index) in cardList" :key="index" cols="4" md="2">
            <v-card flat elevation="1" height="256" class="my-1 card_item" max-width="215">
              <v-img v-if="item.project.image" height="112" :src="item.project.image"></v-img>
              <v-img v-else height="112" src="../../../assets/defaultImg.png"></v-img>
              <v-card-title class="text-subtitle-2 text_title">{{ item.project.projectName }} </v-card-title>
              <v-card-text style="padding-top: 4px">
                <div class="text_content">{{ item.project.description }}</div>
              </v-card-text>
              <div class="topRight" v-if="item.collection"><v-icon size="18" color="#fff">mdi-star</v-icon></div>
              <v-card-actions class="actions_item">
                <v-spacer></v-spacer>
                <v-menu offset-y tile>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list shaped>
                    <v-list-item
                      link
                      class="menu_list"
                      style="min-height: 32px"
                      selectable
                      v-for="(e, index) in operateList"
                      :key="index"
                      @click="EditProjectClick(e, item)"
                    >
                      <v-list-item-title v-if="e.key == 5" class="right_select" style="font-size: 12px">{{
                        item.collection ? '取消收藏' : '收藏项目'
                      }}</v-list-item-title>
                      <v-list-item-title v-else class="right_select" style="font-size: 12px">{{
                        e.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </v-col>
          <div class="zanwu" v-if="cardList.length <= 0">
              <img src="../../../assets/NoData.png"/>
              <div>暂无数据</div>
            </div>
        </v-row>
      </v-tabs-items>
      <div class="pagination">
        <v-pagination v-model="pageNumber" :length="totalPages" :total-visible="7" @input="pageChange"></v-pagination>
      </div>
    </div>

    <!-- 弹框 -->
    <v-row justify="center">
      <!-- 新建/修改项目/文件 -->
      <v-dialog v-model="ProjectDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ addOnEdit }}{{ addName }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="formUser" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" style="padding: 12px 12px 0">
                    <v-text-field
                      :label="addName + '名称 *'"
                      counter="28"
                      outlined
                      v-model="addContent.projectName"
                      max="20"
                      :rules="rules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="addName != '文件夹'" cols="12" style="padding: 0 12px">
                    <v-textarea
                      label="项目描述 *"
                      counter="48"
                      outlined
                      :rules="rules"
                      v-model="addContent.description"
                      required
                    ></v-textarea>
                  </v-col>
                  <v-col v-if="addName != '文件夹'" cols="12" style="padding: 0 12px">
                    <UploadFile @getImg="UploadFile" :src="addContent.imageUrl" title="项目封面" />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ccc darken-4" @click="ProjectDialog = false"> 关闭 </v-btn>
            <v-btn color="blue darken-2" style="color: #fff" @click="subAddProject(addName != '文件夹' ? '1' : '0')">
              确认
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 提交审阅 -->
      <v-dialog v-model="ReviewDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">提交审阅</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="formReview" v-model="validReview" lazy-validation>
                <v-row>
                  <v-col cols="12" style="padding: 12px 12px 0">
                    <v-text-field
                      label="项目名称 *"
                      outlined
                      v-model="ReviewContent.projectName"
                      max="20"
                      readonly
                      :rules="rules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" style="padding: 0 12px">
                    <v-autocomplete
                      label="审阅人 *"
                      outlined
                      :items="ReviewList"
                      :item-text="(item) => `${item.userName}`"
                      :item-value="(item) => `${item.userId}`"
                      :rules="rules"
                      v-model="ReviewContent.reviewUserId"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" style="padding: 0 12px">
                    <v-textarea
                      label="备注 *"
                      counter="48"
                      outlined
                      :rules="rules"
                      v-model="ReviewContent.remark"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ccc darken-4" @click="ReviewDialog = false"> 关闭 </v-btn>
            <v-btn color="blue darken-2" style="color: #fff" @click="subAddReview()"> 确认 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 删除二次确认框 -->
      <v-dialog v-model="ConfirmDialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5"> {{ ConfirmTitle }} </v-card-title>
          <v-card-text>{{ ConfirmDetails }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" text @click="ConfirmDialog = false"> 取消 </v-btn>
            <v-btn color="light-blue accent-3" text @click="deleteConfirm"> 确认 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {
  addProject,
  getCollection,
  updateProject,
  deleteProject,
  Relation,
  getshowFolder,
  putMoveFolder,
  getProjectChild,
  serachProject,
  insertReviewUser,
  getOrganizationRelation,
} from '@/api/project'
import { getOid } from '@/utils/auth'
import uploadFile from '../../../components/uploadFile.vue'
export default {
  components: {
    UploadFile: uploadFile,
  },
  data: () => ({
    tipsInfo: false,
    valid: true,
    validReview: true,
    tipsInfoType: 'success',
    tipsInfoContent: '',
    search: '',
    selectedItem: '',
    tab: null,
    deleteId: '',
    pageNumber: 1,
    pageSize: 12,
    totalPages: 1,
    //tab列表
    items: ['我参与的', '我管理的'],
    navigationList: [
      {
        text: '我的项目',
        userProjectRelId: null,
      },
    ],
    //移动文件夹面包屑
    navigationListWj: [],
    // 项目操作列表
    operateList: [
      // { key: 1, name: '封面信息编辑' },
      // { key: 2, name: '团队成员设置' },
      // { key: 4, name: '提交审阅' },
      { key: 5, name: '收藏项目' },
      // { key: 6, name: '删除项目' },
    ],

    // 选择文件夹
    FolderDialog: false,
    FolderList: [],
    FolderItem: '',
    checkFolder: '',
    checkFolderID: null,

    //文件夹操作列表
    folderOperateList: [
      { key: 1, name: '重命名' },
      { key: 2, name: '移动文件夹至' },
      { key: 3, name: '删除文件夹' },
    ],
    //数据列表
    cardList: [],
    ProjectDialog: false, // 新增/修改项目/文件夹
    addOnEdit: '新建',
    addName: '',
    addContent: {
      // 新建/修改内容
      projectName: '',
      description: '',
      projectId: '',
      imageUrl: null,
    },

    //提交审核
    ReviewDialog: false,
    ReviewContent: {
      projectName: '',
      projectId: '',
      reviewUserId: '',
      remark: '',
    },
    //审阅人列表
    ReviewList: [],

    //二次确认
    ConfirmDialog: false,
    ConfirmTitle: '',
    ConfirmDetails: '',
    rules: [(value) => !!value || '请输入'], // 验证
  }),
  created() {
    this.getProjectList()
  },
  watch: {
    search(val) {
      if (val == null || val == '') {
        this.getProjectList()
      }
    },
    ProjectDialog(val) {
      if (!val) {
        (this.addContent.projectName = ''),
          (this.addContent.description = ''),
          (this.addContent.projectId = ''),
          (this.addContent.imageUrl = null)
      }
    },
  },
  mounted: function () {},
  methods: {
    //获取审阅人列表
    getRelation() {
      getOrganizationRelation({ organizationId: getOid() })
        .then((res) => {
          this.ReviewList = res.data
        })
        .catch(() => {})
    },
    toProject(item) {
      console.log(item)
      this.$router.push({
        path: `/triz/${item.project.projectId}`,
        query: item,
      })
    },
    UploadFile(e) {
      console.log('UploadFile', e)
      this.addContent.imageUrl = e
    },
    //查询事件
    PostSearch(e) {
      console.log(e)
      if (this.search) {
        this.getSerachProject(this.search)
      } else {
        if (this.navigationList.length > 1) {
          this.cardDetailsClick(this.navigationList[this.navigationList.length - 1])
        } else {
          this.getProjectList()
        }
      }
    },
    //查询我的项目列表
    getSerachProject(val) {
      serachProject({organizationId: getOid(), projectName: val,projectFlag:'2', pageNumber: this.pageNumber - 1, pageSize: this.pageSize })
        .then((res) => {
          this.cardList = res.data.content
          this.totalPages = res.data.totalPages
        })
        .catch(() => {})
    },
    //面包屑跳转
    jumpfile(val) {
      if (!val.userProjectRelId) {
        let list = this.navigationList
        this.navigationList = []
        this.navigationList.push(list[0])
        this.getProjectList()
      } else {
        let list = this.navigationList
        this.navigationList = []
        let number = true
        list.map((item) => {
          if (item.userProjectRelId == val.userProjectRelId) {
            if (number) {
              this.navigationList.push(item)
            }
            number = false
          } else {
            if (number) {
              this.navigationList.push(item)
            }
          }
        })
        this.cardDetailsClick(val)
      }
    },
    //文件夹面包屑
    jumpfilewj(val) {
      let list = this.navigationListWj
      this.navigationListWj = []
      let number = true
      list.map((item) => {
        if (item.userProjectRelId == val.userProjectRelId) {
          if (number) {
            this.navigationListWj.push(item)
          }
          number = false
        } else {
          if (number) {
            this.navigationListWj.push(item)
          }
        }
      })
      let id = this.navigationListWj[this.navigationListWj.length - 1].userProjectRelId
      this.getshowFolder(id == null ? 'isnull' : id)
    },
    //分页
    pageChange(e) {
      this.getProjectList()
    },
    // 获取文件夹列表
    getshowFolder(val) {
      let id = null
      if (!val) {
        this.navigationListWj = []
        this.navigationListWj = this.navigationList
        id = this.navigationListWj[this.navigationListWj.length - 1].userProjectRelId
      } else if (val == 'isnull') {
        id = null
      } else {
        id = val
      }
      getshowFolder({id:id,organizationId: getOid(),})
        .then((res) => {
          this.FolderList = res.data
        })
        .catch(() => {})
    },
    //选中文件夹
    checkedFolder(val, index) {
      this.checkFolderID = index
      this.checkFolder = val.userProjectRelId
    },
    //获取选择文件夹下级目录
    FolderdetailsClick(val) {
      getshowFolder(val.userProjectRelId)
        .then((res) => {
          this.FolderList = res.data
          this.navigationListWj.push({
            text: val.project.projectName,
            userProjectRelId: val.userProjectRelId,
          })
        })
        .catch(() => {})
    },
    //移动项目
    moveConfirm() {
      let data = {
        currentPId: this.FolderItem,
        toPid: this.checkFolder,
      }
      putMoveFolder(data)
        .then((res) => {
          this.FolderDialog = false
          if (this.navigationList.length > 1) {
            this.cardDetailsClick(this.navigationList[this.navigationList.length - 1])
          } else {
            this.getProjectList()
          }
        })
        .catch(() => {})
    },
    //获取我的项目列表
    getProjectList() {
      getCollection({organizationId: getOid(), pageNumber: this.pageNumber - 1, pageSize: this.pageSize })
        .then((res) => {
          this.cardList = res.data.content
          this.totalPages = res.data.totalPages
        })
        .catch(() => {})
    },
    //获取列表文件夹下级目录
    cardDetailsClick(val) {
      this.pageNumber = 1
      this.pageSize = 12
      getProjectChild({ id: val.userProjectRelId, pageNumber: this.pageNumber - 1, pageSize: this.pageSize })
        .then((res) => {
          this.cardList = res.data.content
          this.totalPages = res.data.totalPages
          this.navigationList.push({
            text: val.project.projectName,
            userProjectRelId: val.userProjectRelId,
          })
        })
        .catch(() => {})
    },
    //新增
    addProjectClick() {
      this.ProjectDialog = true
      this.addName = '项目'
      this.addOnEdit = '新建'
      this.$nextTick(function () {
        this.$refs.formUser.resetValidation()
      })
    },
    addFolderClick() {
      this.ProjectDialog = true
      this.addName = '文件夹'
      this.addOnEdit = '新建'
      this.$nextTick(function () {
        this.$refs.formUser.resetValidation()
      })
    },
    //项目操作
    EditProjectClick(e, val) {
      if (e.key == 1) {
        this.ProjectDialog = true
        this.addName = '项目'
        this.addOnEdit = '修改'
        this.addContent.description = val.project.description
        this.addContent.projectName = val.project.projectName
        this.addContent.projectId = val.project.projectId
        this.addContent.imageUrl = val.project.image ? val.project.image : null
      } else if (e.key == 2) {
        this.$router.push({ path: '/my/team', query: { projectId: val.project.projectId } })
      } else if (e.key == 3) {
        this.FolderDialog = true
        this.FolderItem = val.userProjectRelId
        this.getshowFolder()
      } else if (e.key == 4) {
        this.ReviewDialog = true
        this.$nextTick(function () {
          this.$refs.formReview.resetValidation()
        })
        this.getRelation()
        this.ReviewContent.projectName = val.project.projectName
        this.ReviewContent.projectId = val.project.projectId
      } else if (e.key == 5) {
        Relation(val.userProjectRelId)
          .then((response) => {
            this.getProjectList()
            this.tipsInfo = true
            this.tipsInfoType = 'success'
            this.tipsInfoContent = val.collection ? '取消收藏成功' : '收藏成功'
            setTimeout(() => {
              this.tipsInfo = false
            }, 2000)
          })
          .catch(() => {
            this.getProjectList()
            this.tipsInfo = true
            this.tipsInfoType = 'error'
            this.tipsInfoContent = val.collection ? '取消收藏失败' : '收藏失败'
            setTimeout(() => {
              this.tipsInfo = false
            }, 2000)
          })
      } else {
        this.ConfirmDialog = true
        this.deleteId = val.userProjectRelId
        this.ConfirmTitle = '是否要删除此项目？'
        this.ConfirmDetails = '删除后将不可找回，请谨慎操作！'
      }
    },
    //提交审阅
    subAddReview() {
      this.$refs.formReview.validate()
      let data = {
        projectName: '',
        projectId: '',
        reviewUserId: '',
        remark: '',
      }
      insertReviewUser(this.ReviewContent)
        .then((response) => {
          this.ReviewDialog = false
          this.tipsInfo = true
          this.tipsInfoType = 'success'
          this.tipsInfoContent = '提交成功'
          this.ReviewContent = data
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
        .catch((error) => {
          let err = error.response.data
          this.ReviewDialog = false
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoContent = err.message
          this.ReviewContent = data
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
    },
    //文件夹操作
    EditFolderClick(e, val) {
      if (e.key == 1) {
        this.ProjectDialog = true
        this.addName = '文件夹'
        this.addOnEdit = '修改'
        this.addContent.projectName = val.project.projectName
        this.addContent.projectId = val.project.projectId
      } else {
        this.ConfirmDialog = true
        this.deleteId = val.userProjectRelId
        this.ConfirmTitle = '是否要删除此文件夹？'
        this.ConfirmDetails = '删除后文件夹内项目也一并删除，如有需要项目请移至别处！'
      }
    },
    //新建项目、文件夹
    subAddProject(val) {
      let pid = null
      this.$refs.formUser.validate()
      if (val == 0 && !this.addContent.projectName) {
        return false
      } else if (val == 1) {
        if (!this.addContent.projectName || !this.addContent.description) {
          return false
        }
      }
      if (this.navigationList.length > 1) {
        pid = this.navigationList[this.navigationList.length - 1].userProjectRelId
      }
      let data = {
        organizationId: getOid(),
        projectName: this.addContent.projectName,
        description: this.addContent.description,
        type: val,
        pid: pid,
        imageUrl: this.addContent.imageUrl,
        projectId: this.addContent.projectId,
      }
      if (this.addOnEdit == '新建') {
        addProject(data)
          .then((response) => {
            if (this.navigationList.length > 1) {
              this.cardDetailsClick(this.navigationList[this.navigationList.length - 1])
            } else {
              this.getProjectList()
            }
            this.ProjectDialog = false
            this.tipsInfo = true
            this.tipsInfoType = 'success'
            this.tipsInfoContent = '新增成功'
            setTimeout(() => {
              this.tipsInfo = false
            }, 2000)
          })
          .catch(() => {
            if (this.navigationList.length > 1) {
              this.cardDetailsClick(this.navigationList[this.navigationList.length - 1])
            } else {
              this.getProjectList()
            }
            this.ProjectDialog = false
            this.tipsInfo = true
            this.tipsInfoType = 'error'
            this.tipsInfoContent = '新增失败'
            setTimeout(() => {
              this.tipsInfo = false
            }, 2000)
          })
      } else {
        updateProject(data)
          .then((response) => {
            if (this.navigationList.length > 1) {
              this.cardDetailsClick(this.navigationList[this.navigationList.length - 1])
            } else {
              this.getProjectList()
            }
            this.ProjectDialog = false
            this.tipsInfo = true
            this.tipsInfoType = 'success'
            this.tipsInfoContent = '编辑成功'
            setTimeout(() => {
              this.tipsInfo = false
            }, 2000)
          })
          .catch(() => {
            if (this.navigationList.length > 1) {
              this.cardDetailsClick(this.navigationList[this.navigationList.length - 1])
            } else {
              this.getProjectList()
            }
            this.ProjectDialog = false
            this.tipsInfo = true
            this.tipsInfoType = 'error'
            this.tipsInfoContent = '编辑失败'
            setTimeout(() => {
              this.tipsInfo = false
            }, 2000)
          })
      }
    },
    //删除项目、文件夹
    deleteConfirm() {
      deleteProject(this.deleteId)
        .then((response) => {
          this.getProjectList()
          this.ConfirmDialog = false
          this.tipsInfo = true
          this.tipsInfoType = 'success'
          this.tipsInfoContent = '删除成功'
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
        .catch(() => {
          this.getProjectList()
          this.ConfirmDialog = false
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoContent = '删除失败'
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
    },
  },
}
</script>
<style lang="scss">
.myProject {
  .top_content {
    display: flex;
    position: relative;
    .left {
      width: 240px;
      color: #999;
      .v-input {
        position: relative;
        left: -20px;
        top: 16px;
        .v-input__slot {
          box-shadow: none;
          .v-text-field__slot {
            position: relative;
            left: 15px;
            color: #999;
          }
        }
        .v-input__prepend-outer {
          position: relative;
          left: 45px;
          top: 2px;
          z-index: 1;
          .v-icon.v-icon {
            color: #3f81c1 !important;
          }
        }
      }
    }
  }
  .tab_content {
    position: relative;
    margin-left: 16px;
    min-height: calc(100vh - 164px);
    .tab {
      width: 300px;
    }
    .v-card__text,
    .v-card__title {
      padding: 8px 8px 0 8px;
    }
    .v-card__actions {
      padding: 0 8px;
    }
    .menu_list:hover {
      .v-list-item__title {
        color: #3f81c1;
      }
    }
    .tabs_items {
      width: calc(100% - 16px);
      padding-bottom: 80px;
      background: none;
      .folder_card {
        padding-top: 54px;
        .folder-title {
          display: block;
          padding-top: 16px;
          width: 100%;
          text-align: center;
        }
      }
      .card_item {
        cursor: pointer;
        background: #f8f8f8;
        position: relative;
        border-radius: 8px;
        .topRight {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          width: 32px;
          height: 32px;
          background: #ffaf46;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 8px;
          text-align: center;
          line-height: 28px;
        }
        .actions_item {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .text_title {
        line-height: 20px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .text_content {
        font-size: 12px;
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 20px;
      }
      .tabs_items_img {
        width: 136px;
        height: 106px;
        margin: 0 auto;
        .menu_list:hover {
          .v-list-item__title {
            color: #3f81c1;
          }
        }
      }
    }
    .pagination {
      position: absolute;
      right: 0;
      bottom: 20px;
    }
  }
}
</style>
